@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700;800;900&display=swap");

.App,
.toHide {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 1s;
  height: 100vh;
}

.App {
  background: linear-gradient(135deg, #fea034 0%, #90fefc 100%);
}

.app {
  background-color: deepskyblue;
}

.toHide {
  visibility: visible;
  opacity: 100;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #fea034 0%, #90fefc 100%);
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .toHide {
    flex-direction: column;
  }
}
