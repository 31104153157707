@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@400;700&display=swap');

.panel{
    transition: opacity 1s ease-in-out;
    opacity: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    height: 98vh;
    transform: scale(1);
    background-color: black;
    border-width:0.5vh;
    border-style: solid;
    border-color:black;
    cursor: pointer;
    z-index: 11;
    box-shadow: 5px 5px 5px 1px black;
}

#rating{
    transition: 1s;
    opacity: 0;
    display: none;
}

.panel:hover{
    filter: brightness(50%);
}

#panel-text{
    background-color: #EFEFEF;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    font-family: "Oxygen";
    font-size: 1.5rem;
}

#movie-name{
    font-weight: bolder;
}

@media screen and (max-width:1400px) {
    .panel{
        width: 50vw;
        height: 100vh;
    }
}

@media only screen and (max-width: 768px) {
    .panel{
        width: 98vw;
        height: 42vh;
    }
}
